const Navbar = () => {
    return (
        <div className="navbar">
            <u>Navbar</u><br/>
            <br/>
            Buttons:
            - Home
            - About Us
            - Services
            - Projects
            - Contact Us
        </div>
    );
}
 
export default Navbar;