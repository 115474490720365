const Projects = () => {
    return (
        <div className="projects">
            <u>Projects</u><br/><br/>
            - Clients we've worked with<br/>
            - Projects we worked on<br/>
            - Blog Posts<br/><br/>
        </div>
    );
}
 
export default Projects;