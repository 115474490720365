const Footer = () => {
    return (
        <div className="footer">
            <u>Footer</u><br/><br/>
            Links<br/>
            Footer<br/>
        </div>
    );
}
 
export default Footer;