const Journey = () => {
    return (
        <div className="journey">

            <u>Journey</u><br/><br/>

            Let us guide you through our process from start to finish<br/>
            Requirements collection<br/>
            Design Creation<br/>
            Implementation<br/>
            QA<br/>
            Post production support<br/><br/>
        </div>
    );
}
 
export default Journey;